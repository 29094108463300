.circle {
  border-radius: 50%;
}

.pulse {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(126, 126, 126, 0.5);
  }
  100% {
    box-shadow: 0 0 0 40px rgba(211, 211, 211, 0);
  }
}
