:root {
  font-family: 'Roboto';
}

.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
